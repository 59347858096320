import { lazy } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './locales/index'
import reportWebVitals from './reportWebVitals'
import { createHashRouter, RouterProvider } from 'react-router-dom'
// import VConsole from 'vconsole';

// routes
import BasicAppScreen from './page/BasicAppScreen'
const TheftReportScreen = lazy(() => import('./page/TheftReportScreen'))
const CircleRadiusScreen = lazy(() => import('./page/CircleRadiusScreen'))
const GeofencingScreen = lazy(() => import('./page/GeofencingScreen'))
const DrawScreen = lazy(
  () => import('./page/GeofencingScreen/children/DrawScreen'),
)
const CircleScreen = lazy(
  () => import('./page/GeofencingScreen/children/CircleScreen'),
)
const TrackPlayBackScreen = lazy(
  () => import('./page/GeofencingScreen/children/TrackPlayBackScreen'),
)
const NewsListScreen = lazy(() => import('./page/news'))
const IFramePlayerScreen = lazy(() => import('./page/IFramePlayer'))

// import OldApp from './App'
const OldApp = lazy(() => import('./App'))

// new VConsole();
const router = createHashRouter([
  {
    path: '/',
    element: <BasicAppScreen />,
    children: [
      {
        path: 'TheftReport/:id',
        element: <TheftReportScreen />,
      },
      {
        path: 'Geofencing',
        element: <GeofencingScreen />,
        children: [
          {
            path: 'draw',
            element: <DrawScreen />,
          },
          {
            path: 'circle',
            element: <CircleScreen />,
          },
          {
            path: 'trackPlayBack',
            element: <TrackPlayBackScreen />,
          },
          {
            path: 'CircleRadiusScreen',
            element: <CircleRadiusScreen />,
          },
          {
            path: 'oldApp',
            element: <OldApp />,
          },
        ],
      },
      {
        path: 'news',
        element: <NewsListScreen />,
      },
      {
        path: 'IFramePlayer',
        element: <IFramePlayerScreen />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<RouterProvider router={router} />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
