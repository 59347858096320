import { useMount } from 'ahooks'
import { useState } from 'react'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'

function GetQueryValue(queryName: string) {
  var query = decodeURI(window.location.search.substring(1))
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == queryName) {
      return pair[1]
    }
  }
  return null
}

const BasicAppScreen = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  useMount(() => {
    switch (location.pathname) {
      case '/news':
        if (location.search) {
          navigate(`news${location.search}`)
        } else {
          navigate(`news`)
        }
        break
      case '/IFramePlayer':
        navigate(`IFramePlayer`)
        break
      default:
        const id = GetQueryValue('id')
        if (id) {
          navigate(`TheftReport/${id}`)
        } else {
          navigate(`Geofencing`)
        }
        break
    }
    setVisible(true)
  })
  if (!visible) return null
  return <Outlet />
}

export default BasicAppScreen
